$base-space:12px;
$base-font-size:14px;
$singleColumDisplay:1152px;//
$mobileDisplay:768px - 1px;//
$mobileDisplayWide:480px;//

a{
	@include link-underline(none-to-line);
	//link-colors($normal, $hover, $active, $visited, $focus)
	// @include link-colors(
	// 	$link-color,
	// 	$link-color,
	// 	$link-color,
	// 	$link-color,
	// 	$link-color
	// );
	word-wrap:break-word;
}

ul{
	@extend %reset-list;
}
small{
	line-height:$base-space*1.5;
}

/* body */
body{
	overflow-x:hidden;
	//min-width:1200px - $base-space * 2; //レスポンシブ対応前
	//margin:$base-space;
	font-family:$base-font-family;
	font-size:$base-font-size;
	color:$base-color;
	background-color:$white;
	text-rendering: optimizeLegibility; //文字詰め
	@media print,screen and (max-width:1200px) {
		//zoom:0.8;
	}
	@media print{
		font-size: $base-font-size*0.8;
	}
}
#body{
	overflow: hidden;
	margin:$base-space;
	border:$base-space solid $yellow;
}
/* container */
#container{
	padding-bottom:30px;
	@media print{
		min-width: 768px;
	}
}

/* content */
#container-content{
	@extend %clearfix;
	width:100%;
	padding-top:85px;
}
.fb_iframe_widget iframe {
	max-width: none !important;
}

%border-box{
	box-sizing: border-box;
}
.main{
	@extend %border-box;
	//float:left;
	width:75%;
	margin-left:auto; margin-right:auto;
	padding-bottom:2em;
	@media print{
		width: 90%;
	}
	@media print,screen and (max-width:$singleColumDisplay) {
		float: none;
		margin:0 auto;
	}
	@media print,screen and (max-width:$mobileDisplayWide) {
		width: 85%;
	}
	h1.pageTitle{
		font-weight: 300;
		line-height: 1.3;
	}
	h2{
		@extend %inline-block;
		margin:$base-space*4 0 0;
		padding:$base-space;
		border:$base-space*0.5 solid;
		line-height: 1.2;
		&:after{
			display: block;
			content: "";
		}
	}
	h3 ,
	h4 {
		margin:0; text-align: left;
		font-size: 18px; font-family:$en-font; font-weight:100;
	}
	h3 {
		margin-top: 18px;
		margin-bottom: 5px;
	}
	h4 {
		font-size: 18px;
		margin-bottom: 5px;
		font-weight:500;
	}
	h5 {
		font-size: 16px;
		margin-bottom: 5px;
		font-weight:500;
	}

	i{
		display:inline-block;
		font-family:$icon-font;
		font-style:normal;
		font-weight:normal;
		font-size:30px;
	}
	ul{
		margin-top: 1em;
		margin-bottom: 1em;
	}
	li{
		margin-bottom: 0.5em;
		line-height: 1.5;
	}
}
aside.note,
section,
.post{
	position: relative;
	max-width: 53em;
	margin:2em auto;
}
section,
.post{
	p{
		text-align: justify;
		line-height: 2;
		letter-spacing: 0.01em;
	}
}
.note{
	p{
		line-height: 1.5;
	}
}
.entry{
	img{
		max-width: 100%; height:auto;
	}
	br + img{
		margin-top: 1em;
	}
}

%wordbreak{
	display: block;
	content:"\a";
	white-space: pre;
}

#container-aside{
	clear:both;
	@extend %clearfix;
	padding:18px 28px 0;
	border-top:$base-space solid $yellow;
	text-align:center;
	@media print{
		display: none;
	}
	@media print,screen and (max-width:$singleColumDisplay) {
		section.column3{
			float: none;
			width: 90%;
			margin:0 auto 18px;
		}
	}
	@media print,screen and (max-width:$mobileDisplayWide) {
		padding:18px 0 0;
	}
	h2,h3,section{
		margin-top: 0;
		margin-bottom: 0;
	}
	h2{
		display: inline;
		text-transform: uppercase;
		border-bottom: 2px solid;
		font-weight: 500;
		font-size: 18px;
		&:after{
			@extend %wordbreak;
			height: 20px;
		}
	}
	h3{
		text-align: left;
		text-transform: uppercase;
		font-weight: 100;
		font-size: 15px;
	}
}

//3カラム
.columns{
	@extend %clearfix;
}
.column3{
	float:left;
	width:30%;
	margin-bottom:18px;
	&:nth-of-type(3n+2){
		margin-left: 5%;
		margin-right: 5%;
	}
	@media print,screen and (max-width:$singleColumDisplay) {
		float: none;
		margin:0 auto 3em;
		width:100%;
		&:nth-of-type(3n+2){
			margin:0 auto 3em;
		}
	}
}

//サムネイル
li.thumb{
	position: relative;
	height:90px;
	margin-top:18px;
	padding-left:160px + 10px;
	text-align:left;
	a{
		color: $black;
		&:hover *{
			@include opacity(0.8);
			text-decoration: none;
		}
	}
	p{
		margin: 0;
		font-size: 12px;
	}
	.date{
		position: absolute; z-index:1;
		top:0; right:1em;
	}
	.num{
		position: absolute; z-index: 2;
		top:0; left:0;
		font-family: $source-font;
		font-size: 30px;
		color: rgba($blue, 0.8);
	}
	.pageTitle{
		height: 36px;
		margin: 0 80px 0 0;
		overflow: hidden;
		line-height: 18px;
		text-align: left;
		font-size: 14px;
	}
	.description{
		height: 56px;
		margin: 4px 1em 0 0;
		overflow: hidden;
		font-size: 12px;
		line-height: 18px;
		text-align: justify;
		&:after{
			position: absolute;
			right: 0; bottom: 0;
			content: "…";
		}
	}
	.photo{
		position: absolute;
		top: 0; left:0;
		width: 160px; height:90px; min-height:90px;
		margin:0;
		background-color:tint($black,80%);
		&:before{
			position: absolute;
			top:8px; left:8px;
			content:"DORP";
			width: 138px;
			line-height: 68px;
			border:3px double;
			text-align: center;
			font-weight: 100;
			font-family: $en-font;
			font-size: 30px;
			color: tint($black,65%);
		}
		img{
			position: relative; z-index: 1;
		}
	}
	@media print,screen and (max-width:$mobileDisplayWide) {
		height: auto;
		padding-left:0;
		.pageTitle{
			height: auto;
			margin: 6px 0 0;
		}
		.photo{
			position: relative;
			top:auto; left:auto;
			max-width: 100%; height:auto;
			img{
				width: 100%; height:auto;
			}
		}
	}
}

//cover トップイメージのレイアウト
%cover{
	position: relative;
	@extend %inline-block;
	>a{
		display: block;
		position: relative;
	}
	.num{
		position: absolute;
		top:0; left:0;
		font-family: $source-font;
		font-size: 100px;
		color: rgba($blue, 0.8);
	}
	.cover-title{
		position: absolute;
		left:0; bottom:0;
		margin:0;
		line-height: 1;
		font-size: 24px;
		span{
			background: rgba($white, 0.66);
			&.subname{ font-size:14px; }
			&.name{ font-size:42px; }
			&.name-en{ font-size:14px; vertical-align:22px; }
			&:after{
				@extend %wordbreak;
				height: 3px;
			}
		}
	}
	.cover-photo{
		margin: 0;
		overflow: hidden;
		img{
			//width: 100%; height: auto;
		}
	}
	@media print,screen and (max-width:$mobileDisplay) {
		.num,
		.cover-title span{
			zoom:0.8;
		}
	}
	@media print,screen and (max-width:$mobileDisplayWide) {
		.num,
		.cover-title span{
			zoom:0.5;
		}
	}
}

.gallery{
	@extend %clearfix;
	li{
		float: left;
	}
}

//ページネーション
.pagenation{
	clear:both;
	margin:2em 0;
	text-align:center;
	a,span{
		@include inline-block;
		min-width: 2em;
		margin: 0 0.5em;
		line-height: 2em;
		font-family: $en-font;
		font-weight: 700;
	}
	a{
		//@extend .exlink;
		display: inline-block;
		min-width: 2em;
		margin: 0 0.5em;
		line-height: 2em;
		font-family: "Roboto";
		font-weight: 700;
		// end @extend .exlink;
		&.first,
		&.last,
		&.previouspostslink,
		&.nextpostslink,
		&.arrow{
			font-family: $arrow-font;
		}
	}
	.pages,.jp-next,.jp-previous{
		width: 6em;
	}
	.current{
		border-radius: 4px;
		background: $base-color;
		color: $body-background;
	}
}

//responsive part

//@media print,screen and (max-width:1000px) {}
@media print,screen and (max-width:768px) {
}
@media print,screen and (max-width:640px) {
	#dorpLogoW{ display:none; }
	#container-header>p{ text-align:center; }
}
//@media print,screen and (max-width:320px) { }
