body.interview #main{
	%wordbreak{
		display: block;
		content:"\a";
		white-space: pre;
	}
	.cover{
		@extend %cover;
		display: block;
		.cover-photo img{
			width: 100%; height: auto;
		}
	}
	#indexgroup{
		@extend .post;
	}
	article.index-post{
		margin-bottom: 4em;
		header{
			@extend %cover;
			display: block;
		}
		a{
			padding-top: 66.666%;
			height: 0;
		}
		.cover-title, .num{
			z-index: 1;
		}
		img{
			position: absolute;
			top: 0;
			width: 100%; height: 100%;
			object-fit: cover;
		}
		.entry{
			line-height: 1.5;
		}
	}
	section h2{
		margin-bottom: 0;
		padding:0 0.4em 28px;
		border:none;
		border-left:3px solid;
		font-size: 19px;
		line-height: 28px;
		letter-spacing: 0.01em;
		text-align: justify;
	}
	section h3{
		margin-top: 0;
	}
	.section-lead{
		line-height: 2;
		h2{
			margin-top: 0;
			padding: 0;
			border: none;
		}
	}
	section div.column{
		//$column-count:3;
		margin-bottom: 2em;
		padding-bottom: 2em;
		border-bottom:1px dashed $gray;
		column-count: 2;
		column-gap: 3em;
		@media print,screen and (max-width:768px) {
			column-count: 1;
		}
		@media print,screen and (max-width:640px) {
			column-count: 1;
		}
		text-align: justify;
		h3{
			margin-bottom:28px;
			text-align: justify;
			line-height: 28px;
			letter-spacing: -1px;
			font-family: $serif-font-family;
			font-size: 18px;
			font-weight: bold;
			&:before{
				float: left;
				content: '';
				width: 0.6em; height:1.25em;
				border-left:3px solid;
			}
		}
		p{
			margin-top: 0;
			margin-bottom: 2em;
			text-align: justify;
			line-height: 2;
			letter-spacing: 0.01em;
			text-indent: 2.02em;
			&:last-child{
				margin-bottom: 0;
			}
		}
		img{
			display: block;
			position: relative;
			top:0; right:0;
			max-width: 100%;
			height: auto;
			zoom:0.5;
		}
	}
	section.profile{
		@extend %clearfix;
		h3{
			margin: 0;
			line-height: 1;
			letter-spacing: 0.25em;
			text-align: right;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 12px;
		}
		dl{
			float: right;
			position: relative; z-index: 1;
			min-width: 18em;
			width: calc(66% - 3em);
			margin: 12px 0 0;
			dt{
				margin-bottom: 6px;
				line-height: 18px;
				font-size: 18px;
				span{
					display: block;
					letter-spacing: 2px;
					font-size: 10px;
				}
			}
			dd.photo{
				position: absolute;
				top:0; left:-150px;
				margin-bottom: $base-space;
				img{ width:130px; height:auto; }
				@media print,screen and (max-width:$mobileDisplay) {
					position: static;
				}
			}
			dd{
				margin: 0;
				line-height: 18px;
				letter-spacing: 1px;
				text-align: justify;
				font-size: 12px;
			}
		}
	}
	section.share{
		.fb_iframe_widget{
			vertical-align: top;
		}
	}
}
