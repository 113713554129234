//	mixin.scss versiton 1.0 update 2013.06.15
//
//	auther planpot
//
//	@include
//	利用方法・説明一覧
//
//	@include clearfix; //floatのクリア
//	@include floatLeft($right:20px,$margin:0); //floatさせる左 マージン調整付き
//	@include floatRight($left:20px,$margin:0); //floatさせる左 マージン調整付き
//	@include structureInner($width,$color:transparent); //横幅100%の要素のinner指定
//	@include absolute($top:0,$right:auto,$bottom:auto,$left:0); //絶対配置
//	@include navigation($float:left); //リストをリセットしてfloatする水平ナビ用
//	@include splitedNavigation($image,$hover,$selected,$width,$height,$class:false) //cssスプライトの水平ナビ用
//

$use_clearfix: false !default;
@mixin use_clearfix() {
	@if $use_clearfix {}
	@else {
		$use_clearfix: true;
		.clearfix{
			zoom:1;
			&:after { display:block; content:""; clear:both; }
		}
	}
}
@mixin clearfix {
	zoom:1;
	&:after { display:block; content:""; clear:both; }
}
@mixin font-reset($size:100%){
	font-size:$size; font-weight:normal; font-style:normal;
}
@mixin opacity($alpha:0.5) {
	opacity:$alpha;
	@if $support-ie6 or $support-ie7 { filter: alpha(opacity=$alpha*100); }
	@if $use-prefix-ms  { -ms-filter: "alpha(opacity=#{$alpha*100})"; }
	@if $use-prefix-moz { -moz-opacity: $alpha; }
	@if $support-ie6 or $support-ie7 or $use-prefix-ms { zoom:1; }
}
@mixin background-rgba($color,$alpha){
	$rgba: rgba($color, $alpha);
	$ie-hex-str: ie-hex-str($rgba);
	background-color: transparent;
	background-color: $rgba;
	@if $support-ie6 or $support-ie7 { filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str}); zoom: 1; }
}

@mixin inline-block { display: inline-block; @if $support-ie6 or $support-ie7 { *display: inline; *zoom: 1; } }

@mixin floatLeft ($right:20px,$margin:0){ float:left ; margin:$margin; margin-right:$right; }
@mixin floatRight($left :20px,$margin:0){ float:right; margin:$margin; margin-left :$left ; }

@mixin column($width:auto,$space:0,$bottomSpace:false){
	@include clearfix;
	margin:0; margin-right:-$space;
	padding-left:0;
	list-style-type:none;
	@if $bottomSpace == false { $bottomSpace:$space; }
	>*{
		float:left;
		width:$width;
		margin:0 $space $bottomSpace 0;
	}
}
@mixin link-underline($type) {
	@if $type == line-to-none { // 1.あり→なし
		&:link, &:visited {text-decoration: underline;}
		&:hover, &:active, &:focus { text-decoration: none;}
	} @else if $type == line { // 2.あり→あり
		&:link, &:visited {text-decoration: underline;}
		&:hover, &:active, &:focus { text-decoration: underline; }
	} @else if $type == none-to-line { // 3.なし→あり
		&:link, &:visited {text-decoration: none;}
		&:hover, &:active, &:focus { text-decoration: underline; }
	} @else if $type == none { // 4.なし→なし
		&:link, &:visited {text-decoration: none;}
		&:hover, &:active, &:focus { text-decoration: none; }
	}
}

@mixin structureInner($width,$color:transparent){
	& > div.inner{
		position:relative; width:$width; margin:0 auto; background-color:$color;
	}
}
@mixin reset-list($float:left){
	margin:0; padding-left:0; list-style-type:none;
}
@mixin navigation($float:left){
	ul{
		@extend clearfix; margin:0; padding-left:0; list-style-type:none;
		li{ float:$float; }
	}
}
@mixin absolute($top:auto,$right:auto,$bottom:auto,$left:auto) {
	position:absolute;
	top:$top; left:$left; right:$right; bottom:$bottom;
}
//水平ナビゲーション
@mixin splitedNavigation($image,$hover,$selected,$width,$height,$class:false){
	$list: $width;
	$offset : 0;
	@include navigation;
	li>a{
		display:block; overflow:hidden; padding-top:$height; height:0; line-height:$height; text-align:center;
		background-image:url($image);
		&:hover{ background-image:url($hover); }
	}
	@for $i from 1 through length($list) {
		li:nth-child(#{$i})>a{ width:nth($list,$i)+px; background-position:#{$offset}px 0; }
		$offset : $offset - nth($list,$i);
	}
	@if $class != false {
		$list : $class;
		@for $i from 1 through length($list) {
			$pageClass: nth($list,$i);
			body.#{$pageClass} & li.#{$pageClass}>a{ background-image:url($selected); }
		}
  }
}
//アニメーション
@mixin animate($time:500ms, $prop:all){
	-webkit-transition: $prop $time cubic-bezier(0.165, 0.840, 0.440, 1.000);
	   -moz-transition: $prop $time cubic-bezier(0.165, 0.840, 0.440, 1.000);
		-ms-transition: $prop $time cubic-bezier(0.165, 0.840, 0.440, 1.000);
		 -o-transition: $prop $time cubic-bezier(0.165, 0.840, 0.440, 1.000);
			transition: $prop $time cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
	-webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
	   -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
		-ms-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
		 -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
			transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */ }

// @mixin bgImage
//
//背景画像
//使用方法
//$top: sprite-map("sprite_top/*.png");
//$sprites-img01: sprite-url($top);
//@include sprite-background(画像名, $s1, $sprites-img01);

//@mixin bgImage : エラー出るため仕様しないこと。
@mixin bgImage($sprite, $spritePath, $name, $display: block, $device-pixel-ratio: 2) {
	@include sprite-background-image($name, $sprite, $spritePath, $display, $device-pixel-ratio);
	@include sprite-background-size($name, $sprite, $spritePath, $device-pixel-ratio);
	@include sprite-background-position($name, $sprite, $spritePath, $device-pixel-ratio);
}
@mixin sprite-background-image($name, $sprite, $sprite-url, $spritePath, $display: block, $device-pixel-ratio: 2){
	@extend %hide-text;
	display:$display;
	background-image:$sprite-url; //url("../images/"+$spritePath);
	background-size: image-width($spritePath) / $device-pixel-ratio;
}
@mixin sprite-background-size($name, $sprite, $spritePath, $device-pixel-ratio: 2) {
	$_width:  image-width(sprite-file($sprite, $name)) / $device-pixel-ratio;
	$_height: image-height(sprite-file($sprite, $name)) / $device-pixel-ratio;
	height: $_height;
	width: $_width;
}
@mixin sprite-background-position($name, $sprite, $spritePath, $device-pixel-ratio: 2) {
	$_pos: sprite-position($sprite, $name);
	$_x: round(nth($_pos, 1) / $device-pixel-ratio);
	$_y: round(nth($_pos, 2) / $device-pixel-ratio);
	background-position: $_x $_y;
}
//一括指定
@mixin sprite-group($imagePath,$imageExtension,$class,$device-pixel-ratio:2,$hoverText:false){
	$display: block;
	$path:$imagePath+"*"+$imageExtension;
	$sprite: sprite-map($path, $layout:smart);
	$sprite-url: sprite-url($sprite);//一度キャッシュさせる＆表示用
	$spritePath: sprite-path($sprite);
	$imageName:"/cms/wp-content/themes/h-hattatsu/images/main/center"+$imageExtension;
	$list: $class;
	@for $i from 1 through length($list) {
		$name: nth($list,$i);
		%#{$name}{
			@include sprite-background-image($name, $sprite, url($imageName), $spritePath, $display, $device-pixel-ratio);
			@include sprite-background-size($name, $sprite, $spritePath, $device-pixel-ratio);
			@include sprite-background-position($name, $sprite, $spritePath, $device-pixel-ratio);
			@if $hoverText != false { &:hover,&.hover{
				background-position: sprite-position($sprite, "#{$name+$hoverText}");
			}}
		}
	}
}

//水平ナビゲーション

@mixin splitedNavigationWithCompass($imagePath,$imageExtension,$class,$padding:0,$device-pixel-ratio:2,$exHover:false,$exCurrent:false,$compile:horizontal){
	$path:$imagePath+"*"+$imageExtension;
	// $path:"/cms/wp-content/themes/h-hattatsu/images/"+"*"+$imageExtension;
	$sprite: sprite-map($path, $spacing: $padding, $layout:$compile);
	$spritePath: sprite-path($sprite);
	$list: $class;
	@extend %clearfix;
	@extend %reset-list;
	>li{
		float: left;
	}
	>li>a{
		display:block; overflow:hidden; white-space: nowrap; text-indent: 100%;
		background-image:url("/cms/wp-content/themes/h-hattatsu/images/header.png");
		background-repeat:no-repeat;
		background-size: image-width($spritePath) / $device-pixel-ratio;
		//-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/"+$spritePath+"',sizingMethod='scale')";
	}
	@for $i from 1 through length($list) {
		$pageClass: nth($list,$i);
		$image:"#{$imagePath+$pageClass+$imageExtension}";
		$_width:(image-width($image)+$padding) / $device-pixel-ratio;
		$_height:(image-height($image)+$padding) / $device-pixel-ratio;
		$_pos: sprite-position($sprite, "nav_"+$pageClass, $padding/2, $padding/2);
		$_x: round(nth($_pos, 1) / $device-pixel-ratio);
		$_y: round(nth($_pos, 2) / $device-pixel-ratio);
		>li.#{$pageClass}>a{
			width: $_width;
			height: $_height;
			background-position: sprite-position($sprite, "nav_"+$pageClass, $padding/2, $padding/2);
			background-position: $_x $_y;
			@if $exHover == image { &:hover,&.hover{
				background-position: sprite-position($sprite, "nav_"+$pageClass+"_on", $padding/2, $padding/2);
			}}
		}
		@if $exCurrent != false { body.#{$pageClass} & >li.#{$pageClass}>a{ @extend #{$exCurrent}; } }
	}
	@if $exHover != false { >li>a:hover{ @extend #{$exHover}; } }
}

// shortcut
@mixin sprite-background($name, $sprites, $sprites-image, $display: block, $device-pixel-ratio: 2) {
	@include sprite-background-size($name, $sprites, $sprites-image, $device-pixel-ratio);
	@include sprite-background-position($name, $sprites, $sprites-image, $device-pixel-ratio);
}
@mixin splitedNavigationWithCompass2($imagePath,$imageExtension,$class,$padding:0,$retina:false,$exHover:false,$exCurrent:false,$compile:smart){
	$device-pixel-ratio: 2;
	$path:$imagePath+"*"+$imageExtension;
	$sprites-all: sprite-map($imageNameNav, $spacing: $padding, $layout: $compile);
	$sprites-all-image: sprite-path($sprites-all);
	$list: $class;
	@include navigation;
	li>a{
		$_width:  image-width($sprites-all-image) / $device-pixel-ratio;
		display:block;
		overflow:hidden;
		white-space: nowrap;
		text-indent: 100%;
		background-image:$sprites-all;
		background-repeat:no-repeat;
		background-size: $_width auto;
	}
	@for $i from 1 through length($list) {
		$pageClass: nth($list,$i);
		$image:"#{$imagePath+$pageClass+$imageExtension}";
		li.#{$pageClass}>a{
			@include sprite-background('#{$pageClass}', $sprites-all, $imageNameNav);
		}
	}
}


//@include sprite-background('star', $sprites-all, $sprites-all-image);
