#sub{
	display:none; // add 2014.10.22
	position: relative; z-index:1;
	@extend %border-box;
	//overflow:hidden;
	//overflow-y:auto;
	float:right;
	width:25%; min-width:260px;
	margin-top:-164px;
	padding:$base-space*2;
	@media print,screen and (max-width:$singleColumDisplay) {
		float: none;
		width: 75%; min-width:610px; max-width:660px;
		margin:2em auto 5em;
		padding:0;
	}
	@media print,screen and (max-width:$mobileDisplay) {
		min-width:0;
	}
	@media print,screen and (max-width:$mobileDisplayWide) {
		width: 85%; max-width:85%;
	}
	@media print {
		display: none;
	}
	section,aside{
		h2{
			margin-bottom: 6px;
		}
		p{
			margin-top:0;
			&.description{
				text-align: justify;
				line-height: $base-space * 1.5;
			}
		}
	}
	#bnrSource{
		h2{
			@extend %inline-block;
			margin:0; padding:0.25em 0.5em;
			background-color: $black;
			color: $white;
		}
		ul{
			@extend %clearfix;
			margin:$base-space 0 0;
		}
		@media print,screen and (max-width:$singleColumDisplay) {
			ul>li{
				float: left;
				width: 50%;
				.description{
					padding-right:1em;
				}
			}
		}
		@media print,screen and (max-width:$mobileDisplay) {
			ul>li{
				float: none;
				width: 100%;
			}
		}
	}
	.research-info,
	.magazine-info{
		@extend %clearfix;
		margin:$base-space 0 $base-space * 2;
		list-style-type: none;
		h2,h3,p{
			margin:0 0 $base-space / 2;
			line-height: $base-space * 1.5;
		}
		h3{
			&:before{
				content: "";
				margin-right: 0.25em;
				font-family: $icon-font;
				color: $blue;
			}
		}
	}
	.research-info{
		.thumb{
			//border:3px solid;
			text-align: center;
			line-height: 90px;
			font-size: 46px;
			background-color: $blue;
			a{
				display: block;
				color: $white;
				&:hover{
					color: scale-lightness(scale-saturation($blue, 100%),50%);
					text-decoration: none;
				}
			}
			span{
				@extend %inline-block;
				position: relative;
				margin:0 1px;
				font-family: $icon-font;
				.icon-checkbox{
					position: absolute;
					left:0; right:0; top:-3px;
					//text-align: center;
					font-size: 12px;
				}
				&.icon-mobile span{
					top:0;
				}
			}
		}
	}
	.magazine-info{
		.thumb{
			border:3px solid;
			text-align: center;
			line-height: 90px - 6px;
			color: $blue;
		}
		.source-logo{
			letter-spacing: 0.2em;
			font-family: $source-font;
			font-size: 30px;
			font-weight: normal;
			vertical-align: -8px;
		}
	}
	.social-banner{
		overflow: hidden;
		@media print,screen and (max-width:$singleColumDisplay) {
			float: left;
			width: 50%;
			max-width: 300px;
		}
		@media print,screen and (max-width:$mobileDisplay) {
			float: none;
			width: 100%;
			max-width: 100%;
		}
	}
	#facebookBanner{
		@media print,screen and (max-width:$singleColumDisplay) {
			float: right;
		}
		@media print,screen and (max-width:$mobileDisplay) {
			float: none;
		}
		.fb-like-box *{
			height: 84px !important;
			background-color: $pure-white;
			border:1px solid $gray;
			border-radius:3px;
		}
	}
	#twitterBanner{
		@media print,screen and (max-width:$singleColumDisplay) {
			clear: both;
		}
	}
	.aboutus{
		@media print,screen and (max-width:$singleColumDisplay) {
			float: right;
			width: 50%;
			max-width: 300px;
		}
		@media print,screen and (max-width:$mobileDisplay) {
			float: none;
			width: 100%;
			max-width: 100%;
		}
		h2{
			margin:0 0 $base-space*1;
			line-height: $base-space*2.5;
		}
		dl{
			@extend %clearfix;
		}
		dt{
			line-height: $base-space*2;
		}
		dd{
			float: left;
			margin: 0 1em 0 0;
			line-height: $base-space*1.5;
			font-size: x-small;
			&.souzou{
				width: 100%;
				margin: 2em 0;
				text-align: right;
			}
		}
	}
}
