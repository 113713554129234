// # Support

$support-ie6: false !default;
$support-ie7: true !default;
$support-ie8: true !default;
$support-ie9: true !default;
$support-mozilla: true !default;
$support-webkit : true !default; // Chrome, Safari
$support-opera  : true !default;

// # Vendor-prefixed CSS Property

$use-prefix-webkit: true !default;
$use-prefix-moz   : true !default;
$use-prefix-ms    : true !default;
$use-prefix-o     : false !default;

// # IE

$use-ie-filter    : false !default;
$use-ie-expression: false !default;

//default value

$containerWidth: 1000px !default;
$baseFontFamily: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !default;
$baseFontSize: 12px !default;

// 透明を画像で保管するgem
// https://github.com/aaronrussell/compass-rgbapng
//
// use
// @include rgba-background(rgba(0,0,0,0.75));
// @include rgba-background-inline(rgba(0,0,0,0.75));

//@import "rgbapng";

//CSS3 PIE SUPPORT
// @import "compass/css3/pie";
//$pie-behavior: url("/PIE.htc");
//$pie-base-class: PIE;

//CSS3サポート
//$experimental-support-for-svg: true;
// @import "compass/css3/";
// @import "compass/utilities/sprites";
// @import "compass/typography/links/link-colors";

//CSSリセット
@import "normalize";
@import "ppt-import";
@import "ppt-ui-reset";

//オリジナルmixin追加
@import "ppt-mixin";
@import "ppt-class-column";
@import "ppt-class";
@import "ppt-animation";
@import "ppt-form";

//スマホ用リセット
body{
	-webkit-text-size-adjust: 100%;
}
