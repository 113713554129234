.wp-block-button{
	margin-bottom: 1em;
	&__link{
		display: inline-block;
		padding: 1em;
		background-color: blue;
		color: white;
		border-radius: 3px;
	}
}
.blocks-gallery-grid{
	display: flex;
	align-items: center;
	background-color: white;
}

body#event #main{
	&>header,
	section{
		overflow: hidden;
		max-width: inherit;
	}
	.event-item__list{
		padding: 2%;
		box-sizing: border-box;
		@media print,screen and (min-width:$mobileDisplayWide+1) and (max-width:$singleColumDisplay) {
			&{
				float: left;
				width: 50%;
			}
			&:nth-child(2n+1){
				clear: left;
			}
		}
		@media print,screen and (min-width:$singleColumDisplay+1) {
			&{
				float: left;
				width: 33.33%;
			}
			&:nth-child(3n+1){
				clear: left;
			}
		}
	}
}
.event-post{
	&__thumb{
		position: relative;
		overflow: hidden;
		padding-top: 56.25%;
		background: black;
		& > * {
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
			display: flex;
			align-items: center;
		}
		img{
			width: 100%;
			height: auto;
		}
	}
	//イベント概要ほか
	&__info{
		border: 1px solid;
		padding: 14px 28px;
		@media print,screen and (max-width:$mobileDisplayWide){
			margin-left: -14px;
			margin-right: -14px;
			padding: 14px;
		}
	}
	&__info-lists{
		overflow: hidden;
		padding-bottom: 1em;
		border-bottom: 1px solid;
		dt, dd{
			float: left;
			margin-bottom: 0.5em;
			@media print,screen and (max-width:$mobileDisplayWide){
				float: none;
			}
		}
		dt{
			clear: left;
			min-width: 6em;
			@media print,screen and (min-width:$mobileDisplayWide+1){
				border-right: 1px solid;
			}
		}
		dd{
			margin-left: 0;
			padding-left: 1em;
			@media print,screen and (max-width:$mobileDisplayWide){
				border-left: 1px solid;
			}
		}
		ul{
			margin: 0;
		}
		p{
			margin: 0;
		}
	}
	//other
	iframe{
		max-width: 100%;
	}
}
//option
.dt-text{
	&--2{
		letter-spacing: 2em;
	}
}
body.event #main{
	.archive-post{
		min-height: 20em;
		header,footer,.entry{
			padding-left:33%;
		}
		.photo{
			position: absolute;
			top:0; left:0;
			width: 30%;
			img{
				width: 100%; height:auto;
			}
		}
		footer{
			padding-left:0;
		}
		@media print,screen and (max-width:$mobileDisplayWide) {
			header,footer,.entry{
				padding-left:0;
			}
			.photo{
				position: static;
				width: 100%;
			}
		}
	}
	//イベント用
	.session{
		clear: both;
		margin:$base-space*4 0;
		h3,h4{
			text-align: left;
		}
		h5{
			margin:0;
			line-height: 18px;
			font-size: 12px;
		}
		.profile{
			@extend %clearfix;
			p{
				margin-top: 0;
				line-height: 18px;
				font-size: 12px;
			}
			img{
				width: 100px; height: auto;
			}
		}
	}
}
