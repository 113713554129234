body.about #main{

	h4{
		text-align: center;
	}
	.columns{
		i{
			display:block;
			width:80px; line-height:80px;
			border-radius: 40px;
			margin:0 auto 5px;
			background-color:$pure-white;
			font-family:$icon-font;
			font-style:normal;
			font-weight:normal;
			font-size:30px;
		}
		ul{
			text-align:left;
		}
	}
	.social{
		text-align: center;
	}
	//google+に合わせる
	.twitter>.tw-follow-box,
	.facebook>.fb-like-box{
		background: $pure-white;
		border:1px solid #ccc;
		border:1px solid rgba(0, 0, 0, 0.2);
		border-radius: 3px;
	}
	.twitter>.tw-follow-box{
		@extend %inline-block;
		width:298px - 20px; //height:96px;
		margin:0 auto 3px; padding:10px;
		text-align: left;
		i{
			width:50px; line-height:50px;
			margin-right:5px;
			font-size:30px;
			text-align:center;
			color:#33ccff;
		}
	}
}
