body.research #main{

	.inlineFrame{
		display:block;
		overflow:scroll;
		height:400px;
		padding:10px 20px;
		background:$pure-white;
		border:1px solid $gray;
		font-size:small;
		section{
			width:auto; padding:0; margin:0; background:none; box-shadow:none; 
		}
		#introSection{
			top:0; margin:0;
		}
	}
	.questionTitle{
		margin:$base-space*4 0 $base-space*2;
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 500;
		span{
			@extend %inline-block;
			border-bottom:2px solid;
		}
	}
	.inlineForm{
		margin:$base-space*2 0;
		border:3px solid;
	}

	section{
		overflow: hidden;
		max-width: 53em - 4;
		padding:2em 2em;
		background:$pure-white;
		border:1px solid $gray;
		@media print{
			max-width: 100%;
			min-width: 600px;
		}
		h2{
			margin-top: 0;
		}
		h3{
			clear: both;
			margin-bottom: 1em;
			padding-top:2em;
		}
		img{
			float: left;
			max-width: 100%; height:auto;
			margin-bottom:2em;
		}
		table{
			float: right;
			width: 40%; min-width:173px;
			margin-bottom:2em;
			th,td{
				padding:0.25em 0.5em;
				border-bottom:1px solid $gray;
				border-right:1px dashed $gray;
				line-height: 1.3;
			}
		}
	}
}