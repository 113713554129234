//
form{
	.require{
		font-size:10px;
		vertical-align: top;
	}
	p>label{
		display: block;
		font-weight: bold;
	}
	table th{
		white-space: nowrap;
		text-align: left;
		vertical-align:middle;
	}
	table ul{
		margin:0; padding:0;
		li{ @include inline-block; margin-right:2em; }
		input{ margin-right:0.5em; }
	}
	select {
		border: 1px solid #c8c8c9;
		margin-bottom: 1px;
		padding: 5px;
	}
	input[type=text],
	input[type=password],
	textarea {
		width: 90%;
	}
	input.name,
	input.kana,
	{
		width: 30%;
	}
	input.tel,
	input.fax,
	input.zip,
	{
		width: 25%;
	}
	input.cname,
	input.ckana,
	input.job,
	input.cjob,
	input.birth,
	input.email,
	{
		width: 50%;
	}
	input.num,
	input.number{
		width: 10%;
	}
	textarea {
		height: 8em;
	}
	.submit-container{
		text-align:center;
	}
}
