//	mixin.scss versiton 1.0 update 2013.06.15
//
//	auther planpot
//
// standard common class

//placeholder


%column{ float: left; box-sizing: border-box; }
%right{ float: right; box-sizing: border-box; }

%column2-1{ width:50%; }
%column3-1{ width:33.33%; }
%column3-2{ width:33.33%*2; }
%column4-1{ width:25%; }
%column4-2{ width:25%*2; }
%column4-3{ width:25%*3; }
%column5-1{ width:20%; }
%column5-2{ width:20%*2; }
%column5-3{ width:20%*3; }
%column5-4{ width:20%*4; }

.column2-1{ @extend %column; @extend %column2-1; }

.column3-1{ @extend %column; @extend %column3-1; }
.column3-2{ @extend %column; @extend %column3-2; }

.column4-1{ @extend %column; @extend %column4-1; }
.column4-2{ @extend %column; @extend %column4-2; }
.column4-3{ @extend %column; @extend %column4-3; }

.column5-1{ @extend %column; @extend %column5-1; }
.column5-2{ @extend %column; @extend %column5-2; }
.column5-3{ @extend %column; @extend %column5-3; }
.column5-4{ @extend %column; @extend %column5-4; }

.column3L,
.column3C,
.column3R,
{
	box-sizing: border-box;
	float: left;
	width: 33.33%;
	padding: 10px;
	@if $support-ie7{
		.ie7 &{
			width: 30.33%;
			padding: 10px 1%;
		}
	}
}
