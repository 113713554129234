//	mixin.scss versiton 1.0 update 2013.06.15
//
//	auther planpot
//
// standard common class

// .PIE {
//   @include pie-element(relative);
// }
// .PIEZ {
//   @include pie-element(z-index);
// }
// .pieCon {
//   @include pie-container;
// }
%gradient {
  //@include pie;
  background-image: linear-gradient(top, #fcfcfc, #cccccc);
}

//placeholder
%clearfix{
	zoom:1;
	&:after { display:block; content:""; clear:both; }
}
%reset{
	margin: 0; padding:0;
	border:none;
}
%reset-margin{
	margin: 0;
}
%reset-list{
	margin:0; padding:0;
	&>li{
		margin:0; padding:0;
		list-style-type: none;
	}
}
%inline-block{
	display: inline-block;
	@if $support-ie6 or $support-ie7 { *display: inline; *zoom: 1; }
}
%hide-text{
	overflow:hidden;
	text-indent:100%;
	white-space:nowrap;
}

//オリジナルリセット
header,footer,section,article,main,nav
{ display:block; margin:0; padding:0; }
.ie7_anon{ height:0; }
address{ font-style:normal; }
.fix-image img,
img.fix-image{
	max-width:100%; height:auto;
}
img{
	vertical-align:bottom; //日本語用にベースライン合わせる
}
img.centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
	}

img.alignright {
	padding: 4px;
	margin: 0 0 2px 7px;
	display: inline;
	}

img.alignleft {
	padding: 4px;
	margin: 0 7px 2px 0;
	display: inline;
	}

.alignright {
	float: right;
	}

.alignleft {
	float: left
	}

.gmap{
	height:400px;
}
