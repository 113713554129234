/* header */
#container-header{
	//overflow:hidden;
	position:relative; z-index:256;
	//position: fixed;
	//top:0; left:0;
	width:100%; min-height:70px; //background:$white;
	//border-top:$base-space solid;
	//@include single-box-shadow(rgba(0,0,0,0.1),0,2px,2px);
	h1{
		position: relative; z-index:2;
		float: left;
		padding:$base-space*2;
		@media print,screen and (max-width:$mobileDisplay) {
			padding:$base-space*2 $base-space*1.5;
			border-bottom:$base-space solid $yellow;
			border-right:$base-space solid $yellow;
			&:after{
				position: absolute;
				left: 100%; top:0;
				content:"";
				height: 76px;
				border-right:$base-space solid $white;
			}
		}
	}
	.tagline{
		margin:0; padding:$base-space*2 $base-space;
		line-height: $base-space*2;
		letter-spacing: 0.5em;
		font-size:x-small;
		@media print,screen and (max-width:$mobileDisplay) {
			display: none;
		}
	}
	#global-nav{
		transition: all 100ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
		position: absolute;
		top:$base-space*5; left:0;
		height: 48px;
		padding-left:184px; padding-right:1em;
		border-bottom:$base-space solid $yellow;
		border-right:$base-space solid $yellow;
		font-size: 15px;
		@extend %reset-list;
		@extend %clearfix;
		li{
			float: left;
			a{
				@extend %inline-block;
				padding:1em;
				font-family: $en-font;
				font-size: 15px;
				color: $black;
				&:hover{
					@include opacity(0.8);
				}
			}
			&.home a{
				width: 1em;
				overflow: hidden;
				white-space: nowrap;
			}
		}
		@media print{
			display: none;
		}
		@media print,screen and (max-width:$mobileDisplay) {
			//display: none;
			position: fixed; z-index:256;
			top:$base-space; right:$base-space; left:auto;
			width: 52px; height:52px;
			padding: 0;
			background: $pure-white;
			//border:none;
			border:12px solid $yellow;
			font-size: 18px;
			&.open{
				width: 10em; height:346px;
			}
			&:before{
				position: fixed;
				top:$base-space * 2; right:$base-space * 2;
				content: '';
				padding: 1em;
				font-family: $icon-font;
				color: $link-color;
				cursor:pointer;
			}
			li{
				overflow: hidden;
				width: 0;
				float: none;
				a{
					opacity:0;
					font-size: 18px;
				}
			}
			&.open li{
				width: 100%;
				display: block;
				a{
					opacity:1;
				}
			}
		}
	}
	#social-nav{
		position: absolute;
		top:$base-space*2; right:$base-space*2;
		@extend %reset-list;
		@media print{
			display: none;
		}
		li{
			@extend %inline-block;
			a{
				overflow: hidden;
				display: block;
				width: 1em; height:1em;
				padding: $base-space*0.25;
				line-height: 1em;
				font-size: 18px;
				color: $gray;
				&:hover{
					text-decoration: none;
				}
			}
			&.tweet a:hover{ color:$twitter-blue; }
			&.facebook a:hover{ color:$facebook-blue; }
			&.googleplus a:hover{ color:$google-orange; }
		}
		@media print,screen and (max-width:$mobileDisplay) {
			top: 8px; right: $base-space*6;
			li{
				a{
					padding:10px;
				}
			}
		}
		@media print,screen and (max-width:$mobileDisplayWide) {
			top: $base-space*6; right: 8px;
			li{
				display: block;
				a{
					padding:8px;
				}
			}
		}
	}
}
.dorpLogo{
	$font-size:9px;
	margin:0;
	padding:1em;
	text-align:center;
	font-family:$en-font;
	color:$black;
	*{
		display: block;
		margin:0;
		line-height:0.95;
	}
	a{
		text-decoration: none;
		color:$black;
	}
	abbr{
		text-decoration: none;
		border:none;
		font-size:$font-size*6.75;
		letter-spacing:-$font-size / 4;
		font-weight:100;
	}
	span{
		margin-top: -0.25em;
		font-weight:500;
		font-size:$font-size;
		text-indent:2px;
	}
}
#main>header{
	position: relative;
	max-width: 53em;
	@media print{
		max-width: 100%;
	}
	margin:2em auto;
	border-top:6px solid;
	border-bottom:3px solid;
	h1{
		overflow: hidden;
		margin: $base-space 0 0;
		.page-title{
			float: left;
			margin-right: 5px;
			text-transform: uppercase;
			letter-spacing: -1px;
			line-height: 50px;
			font-size: 65px;
			font-weight: 900;
		}
		.site-title{
			display: block;
			letter-spacing: 0.5px;
			line-height: 12px;
			font-size: 11px;
			font-weight: 500;
		}
		@media screen and (max-width:$mobileDisplay) {
			.page-title{
				font-size: 8vw;
			}
		}
		@media screen and (max-width:$mobileDisplayWide) {
			.site-title{
				display: none;
			}
		}
	}
	.archive-nav{
		position: absolute;
		top:0; right:0;
	}
	.archive-nav-title{
		margin:0;
		&:after{
			@extend %inline-block;
			content: '';
			font-family: $icon-font;
			padding:$base-space;
			&:hover{
				color: $blue;
			}
		}
	}
}

.pan{
	margin-top: 0.25em;
	margin-bottom: 0.75em;
}
