
/* font */
@import url(https://fonts.googleapis.com/css?family=Roboto:900,700,500,300,100);
@import url(https://fonts.googleapis.com/css?family=Quicksand&text=<->);
$arrow-font:'Quicksand', sans-serif;
$en-font:'Roboto';

// @include font-face("FontAwesome",
// 	font-files(
// 		"//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/font/fontawesome-webfont.ttf",
// 		"//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/fonts/fontawesome-webfont.woff",
// 		"//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/fonts/fontawesome-webfont.svg"),
// 		"//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/fonts/fontawesome-webfont.eot"
// 	);
$icon-font:"Font Awesome\ 5 Pro";

@font-face {
    font-family: "sourceFont";
    font-style: normal;
    font-weight: normal;
    font-display:auto;src: url(../fonts/source.eot);
    src: url(../fonts/source.eot?#iefix) format("embedded-opentype"),
		url(../fonts/source.woff) format("woff"),
		url(../fonts/source.ttf) format("truetype"),
		url(../fonts/source.svg#fontawesome) format("svg")
}
$source-font:"sourceFont";

$yuGothic : "YuGothic", "Yu Gothic";
$yuMincho : "YuMincho", "Yu Mincho";
$base-font-family : $en-font, $yuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
//$base-font-family : "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
$serif-font-family: $yuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;

$en-font:$base-font-family;
.en{
	font-family:$en-font;
}
