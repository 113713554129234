//赤
$red:#e60012;
//青
$blue:#2727B1;
$grayish-blue:#7a8da2;
$grayish-blue-sub:#cdd1d2;
$gmap-blue:#c8dce5;
//黄色
$yellow:rgb(251, 255, 204);
$light-yellow:#f5bd22;
$dark-yellow:#c0900b;
//グリーン
$green:#008842;
//黒：#000
$black:#0c0c0b;
$black:#6a6f70;//Contrast raio 5.10:1
//濃いグレー：#333
$dark:#333;
//グレー：#666
$gray:#c9cecf;
//ライトグレー：#ccc
$light-gray:#ccc;
//ブルーグレー
$blue-gray:#abb4be;
//白：#fff
$white:#fffefd;//fff
$pure-white:#fff;
//ベージュ
$beige:#fffbc6;
//見出しテキスト：#333
$light-black:#333;

//白背景とのツートン
$grayish-white:#ecf0f1;

$base-color      : $black;
$text-color 	 : $base-color;
$accent-color    : $red;
$subaccent-color : $green;
$link-color      : $blue;
$link-color-hover: $subaccent-color;
$body-background : $white;

//social color
$twitter-blue:#00acee;
$facebook-blue:#3b5998;
$google-orange:#dd4b39;
$rss-orange:#ee802f;

//selection
::-moz-selection { background: $blue; color: $white; text-shadow: none; }
::selection { background: $blue; color: $white; text-shadow: none; }
