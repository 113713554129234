//
form{
	.error,
	.require{
		color: $accent-color;//$arrowsRed;
	}
	table th{
		padding:0.5em 0 1em;
		background-color: transparent;
		white-space: nowrap;
		text-align: left;
		.subject{
			padding: 5px 10px;
			background-color: $grayish-blue;
		}
		*.submessage{
			display: none;
		}
	}
	table th,
	table td{
		border:none;
		vertical-align:top;
	}
	table ul{
		margin:0; padding:0;
		li{ @include inline-block; margin-right:2em; line-height:30px; }
		input{ margin-right:0.5em; }
	}
	select {
		border: 1px solid #c8c8c9;
		margin-bottom: 1px;
		padding: 5px;
	}
	input[type=text],textarea {
		width: 90%;
		padding: 6px 5px;
		border:none;// 1px solid #c8c8c9;
		border-top-width: 3px;
	}
	input[name=element-1],
	input[name=element-2],
	input[name=element-6],
	input[name=element-7]{
		width: 30%;
	}
	input[name=element-3]{
		width: 25%;
	}
	input[name=element-8]{
		width: 50%;
	}
	input[name=element-10]{
		width: 10%;
	}
	textarea {
		height: 8em;
	}
	.submit-container{
		text-align:center;
	}
}
.submit-container>input,
.btn{
	@include inline-block;
	border-radius: 4px;
	padding:1em 3em;
	background-color:$red;
	border:none;
	border-bottom:5px solid scale-lightness($red, -20%);
	letter-spacing:1px;
	text-decoration:none;
	font-weight:bold;
	color:#fff;
	&:hover{
		@include opacity(0.8);
		background-color:$red;
	}
}
