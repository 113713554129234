body#home{

	#main{
		float: none;
		width: 100%;
		padding-bottom: $base-space;
 		>header{
			display: none;
		}
		#covergroup{
			overflow: hidden;
			overflow-x: scroll;
			width: 100%; height: 450px;
			max-width: 100%;
			margin-top: 0;
			white-space: nowrap;
			.cover{
				@extend %cover;
			}
			@media print,screen and (max-width:$mobileDisplay) {
				height: 200px;
				img{
					height: 200px; width:auto;
				}
			}
			@media print,screen and (max-width:$mobileDisplayWide) {
				height: 140px;
				img{
					height: 140px; width:auto;
				}
			}
		}
		.column{
			@extend %clearfix;
			padding:0 5%;
		}
	}

	#sub{
		@extend %clearfix;
		float: none;
		width: 100%;
		margin: 0; padding:0 5% $base-space*2;
		//border-top: $base-space solid;
		line-height: $base-space*1.5;
		#bnrSource{
			float: left;
			width: 65%;
			ul{
				li{
					overflow: hidden;
					float: left;
					width: 50%;
					h3{
						white-space: nowrap;
					}
					p.description{
						margin-right: 3.25em;
					}
				}
			}
		}
		.aboutus{
			float: right;
			width: 30%;
			margin:0;
			dd{
				&.souzou{
					float: right;
					width: 30%;
					margin: 0;
					img{
						width: 100%; max-width:160px; height:auto;
					}
				}
			}
		}
		@media print,screen and (max-width:$singleColumDisplay) {
			max-width:100%;
			#bnrSource{
				float: none;
				width: 100%;
				margin: 0 auto;
			}
			.aboutus{
				float: none;
				width: 100%; max-width:100%;
				margin:0;
				p#souzouBanner{
					text-align: right;
				}
			}
		}
		@media print,screen and (max-width:$mobileDisplayWide) {
			#bnrSource{
				ul{
					li{
						float: none;
						width: 100%;
					}
				}
			}
		}
	}

}
