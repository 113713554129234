/* footer */
#main footer{
	position: relative;
	max-width: 53em;
	margin:2em auto;
	padding: ($base-space / 2) 0;
	border-top:3px solid;
	border-bottom:3px solid;
	line-height: $base-space*1.5;
}
#container-footer{
	z-index:256;
	position: fixed;
	left:0; right:0; bottom:0;
	max-width: 100% !important;
	margin:0 $base-space;
	line-height:$base-space * 2;
	border-top:$base-space solid $yellow;
	background:$white;
	font-size:12px;
	@media print{
		display: none;
	}
	*{
		@extend %inline-block;
		margin:0; padding:0;
	}
	nav,
	nav ul li{
		margin:0 1em;
	}
	@media print,screen and (max-width:$mobileDisplay) {
		nav>ul{
			display: none;
		}
	}
	.totop{
		position: fixed;
		left:calc(100% - 2.5em); bottom:0;
		font-family: $icon-font;
		a{
			display: block;
			width: $base-space * 2;
			height: $base-space * 2;
			margin-left:-$base-space;
			text-align: center;
			&:hover{
				text-decoration: none;
				opacity:0.5;
			}
		}
		@media print,screen and (max-width:$singleColumDisplay) {
			left:auto; right:$base-space;
		}
	}
}
